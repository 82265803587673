<template>
	<ui-list
		:disableXScroll="true"
		:headings="[
			$tc('models.scope'), $tc('models.permission'),$tc('models.company'),'', $tc('models.allow')
			// ''
		]"
			:items="permissions"
	>
		<template v-slot:default="props">
			<ui-list-data type="tag" :text="props.item.scope" />
			<ui-list-data type="tag" :text="props.item.permission" />
			<ui-list-data type="tag" :text="props.item.companyName" />
			<ui-list-data />
			<ui-list-data><el-checkbox v-model="props.item.hasPermission" /></ui-list-data>
		</template>
	</ui-list>
</template>

<script>
import UiList from "@/components/ui/UiList";
import UiListData from "@/components/ui/UiListData";

export default {
	name: "UserPermissionTable",
	components: {
		UiList,
		UiListData
	},
	props: {
		permissions: {
				required: true,
				type: Array,
		}
	},
}
</script>