<template>
  <ui-form @submit="handleSubmit" @cancel="handleCancel">
    <ui-form-field :label="$t('fields.email')" rules="required|email">
      <el-input type="email" v-model="user.email"></el-input>
    </ui-form-field>

    <ui-form-field :label="$tc('models.role')" rules="required">
      <role-field v-model="user.role"></role-field>
    </ui-form-field>

    <ui-form-field
      v-show="user.role && user.role.name === 'CompanyAdmin'"
      :label="$tc('models.company')"
    >
      <company-field v-model="user.company"></company-field>
    </ui-form-field>

    <ui-form-field
      v-show="user.role && user.role.name === 'BuildingAdmin'"
      :label="$tc('models.company')"
    >
      <building-field v-model="user.building"></building-field>
    </ui-form-field>

    <ui-form-field
      v-show="
        user.role &&
          (user.role.name === 'DepartmentAdmin' || user.role.name === 'User')
      "
      :label="$tc('models.department')"
    >
      <department-field v-model="user.department" />
    </ui-form-field>

    <ui-form-field 
      :label="$tc('models.permissions')"
    >
        <user-permission-table
          :permissions="user.permissions"
        />
    </ui-form-field>
    
  </ui-form>
</template>
<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import RoleField from '@/components/form/RoleField';
import CompanyField from '@/components/form/CompanyField';
import BuildingField from '@/components/form/BuildingField';
import DepartmentField from '@/components/form/DepartmentField';
import UserPermissionTable from '@/components/form/UserPermissionTable';

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  components: {
    UiForm,
    UiFormField,
    RoleField,
    CompanyField,
    BuildingField,
    DepartmentField,
    UserPermissionTable
  },

  methods: {
    handleSubmit() {
      this.$emit('submit');
    },

    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
