<template>
  <div>
    <ui-list
      :headings="[
        $t('labels.department.name'),
        $tc('models.building'),
        $tc('models.role'),
        // ''
      ]"
      :sort="[
        { prop: 'building', type: 'translation' },
        { prop: 'department', prop2: 'name', type: 'level2' },
      ]"
      :items="departments"
    >
      <template v-slot:default="props">
        <!-- Department name -->
        <ui-list-data :text="props.item.departmentName" />

        <!-- Building name -->
        <ui-list-data type="tag" :text="props.item.buildingName" />

        <!-- Role -->
        <ui-list-data type="tag" :text="$t('roles.DepartmentAdmin')" />

        <!-- Progress bar?? -->
<!--        <ui-list-data>-->
<!--          <ui-progress-bar :percentage="getProgress(props.item)"/>-->
<!--        </ui-list-data>-->

<!--        <ui-list-data small>-->
<!--          <ui-link type="danger" uppercase size="small" @click="handleOpenDeleteDepartmentUserModal(props.item)">{{$t('actions.delete')}}</ui-link>-->
<!--        </ui-list-data>-->
      </template>
    </ui-list>

    <!-- Modal displaying warning about deleting department user -->
<!--    <ui-modal :show="showDeleteDepartmentUserModal" :text="$t('confirm.delete.userCourse')" @confirm="handleDeleteDepartmentUser" @cancel="handleCloseDeleteDepartmentUserModal"/>-->
  </div>
</template>

<script>
// import UiLink from "../../../components/ui/UiLink";
import UiList from "../../../components/ui/UiList";
// import UiModal from "../../../components/ui/UiModal";
import UiListData from "../../../components/ui/UiListData";
// import UiProgressBar from "../../../components/ui/UiProgressBar";
// import {deleteUserCourse} from "@/app/users/api";
import {deleteUserCourse, getDepartmentUsersByUser} from "../api";

export default {
  name: "DepartmentConnections",

  components: {
    // UiLink,
    UiList,
    // UiModal,
    UiListData,
    // UiProgressBar
  },

  props: {
    departments: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      showDeleteDepartmentUserModal: false,
      markedDelete: null,
    }
  },

  methods: {
    getTranslation(transList) {
      const trans = transList.filter(t => t.lang === this.$i18n.locale);
      if (trans.length === 0) return '';
      else return trans[0].value;
    },

    /****************
     * Delete Section
     ***************/

    // handleOpenDeleteDepartmentUserModal(departmentUser) {
    //   this.markedDelete = departmentUser;
    //   this.showDeleteDepartmentUserModal = true;
    // },
    //
    // handleCloseDeleteDepartmentUserModal() {
    //   this.markedDelete = null;
    //   this.showDeleteDepartmentUserModal = false;
    // },
    //
    // async handleDeleteDepartmentUser() {
    //   const departmentUser = this.markedDelete;
    //   if(!departmentUser){
    //     return;
    //   }
    //   this.loading = true
    //   try {
    //     await deleteDepartmentUser(departmentUser.id);
    //     this.loading = false
    //     const msg = {
    //       message: this.$t('feedback.delete_success'),
    //       type: 'success'
    //     };
    //     this.$message(msg);
    //
    //     // Refresh list
    //     this.$emit("onDeleteDepartmentUser", departmentUser.id);
    //     this.handleCloseDeleteUserCourseModal();
    //   }
    //   catch(error) {
    //     this.$message({ message: this.$t('errors.general'), type: 'error' })
    //     this.loading = false
    //     this.handleCloseDeleteUserCourseModal();
    //   }
    // },
  },
}
</script>

<style scoped>

</style>