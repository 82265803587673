<template>
  <div>
    <ui-list
      :headings="[this.$t('labels.course.name'), this.$tc('models.building'), this.$t('misc.completion'), '']"
      :items="courses"
      :sort="[
        { prop: 'title', type: 'translation' },
        { prop: 'building', prop2: 'name', type: 'level2' },
        { prop: 'progress', prop2: 'progressPercentage', type: 'level2' }
      ]"
    >
      <template v-slot:default="props">
          <ui-list-data
              :text="getTranslation(props.item.title)"
              :infoHtml="getTranslation(props.item.description)"
          >
          </ui-list-data>

          <ui-list-data type="tag" :text="props.item.buildingName"></ui-list-data>

          <ui-list-data>
            <ui-progress-bar :percentage="getProgress(props.item)"/>
          </ui-list-data>

          <ui-list-data small>
            <ui-link type="danger" uppercase size="small" @click="handleOpenDeleteUserCourseModal(props.item)">{{$t('actions.delete')}}</ui-link>
          </ui-list-data>
      </template>
    </ui-list>

    <!-- Modal displaying warning about deleting user course -->
    <ui-modal :show="showDeleteUserCourseModal" :text="$t('confirm.delete.userCourse')" @confirm="handleDeleteUserCourse" @cancel="handleCloseDeleteUserCourseModal"/>
  </div>
</template>

<script>
import UiLink from "../../../components/ui/UiLink";
import UiList from "../../../components/ui/UiList";
import UiModal from "../../../components/ui/UiModal";
import UiListData from "../../../components/ui/UiListData";
import UiProgressBar from "../../../components/ui/UiProgressBar";
import {deleteUserCourse} from "@/app/users/api";

export default {
  name: "UserCourses",
  components: {
    UiLink,
    UiList,
    UiModal,
    UiListData,
    UiProgressBar
  },

  props: {
    courses: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      showDeleteUserCourseModal: false,
      markedDelete: null,
    }
  },

  methods: {
    getProgress(course) {
      let p = 0;
      if (course.completed) {
        p = 100;
      } else if (
          course.lessons &&
          course.lessons.length > 0 &&
          course.userLessons &&
          course.userLessons.length > 0
      ) {
        p = Math.floor(
            (course.userLessons.length / course.lessons.length) * 100
        );
      }
      return p;
    },

    getTranslation(transList) {
      const trans = transList.filter(t => t.lang === this.$i18n.locale);
      if (trans.length === 0) return '';
      else return trans[0].value;
    },

    // Delete user course
    handleOpenDeleteUserCourseModal(user) {
      this.markedDelete = user;
      this.showDeleteUserCourseModal = true;
    },

    handleCloseDeleteUserCourseModal() {
      this.markedDelete = null;
      this.showDeleteUserCourseModal = false;
    },

    async handleDeleteUserCourse() {
      const userCourse = this.markedDelete;
      if(!userCourse){
        return;
      }
      this.loading = true
      try {
        await deleteUserCourse(userCourse.id, userCourse.userId);
        this.loading = false
        const msg = {
          message: this.$t('feedback.delete_success'),
          type: 'success'
        };
        this.$message(msg);

        // Refresh list
        this.$emit("onDelete", userCourse.id);
        this.handleCloseDeleteUserCourseModal();
      }
      catch(error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' })
        this.loading = false
        this.handleCloseDeleteUserCourseModal();
      }
    },
  },
}
</script>

<style scoped>

</style>