<template>
  <div>
    <ui-list
        :headings="[
        $tc('models.building', 1),
        $tc('models.company'),
        $tc('models.role'),
        // ''
      ]"
        :sort="[
        { prop: 'building', type: 'translation' },
        { prop: 'companyName', type: false },
      ]"
        :items="buildingUsers"
    >
      <template v-slot:default="props">
        <!-- Building name -->
        <ui-list-data :text="props.item.buildingName" />

        <!-- Company name -->
        <ui-list-data type="tag" :text="props.item.companyName" />

        <!-- Role -->
        <ui-list-data type="tag" :text="$t('roles.BuildingAdmin')" />
      </template>
    </ui-list>
  </div>
</template>

<script>
import UiList from "../../../components/ui/UiList";
import UiListData from "../../../components/ui/UiListData";

export default {
  name: "BuildingConnections",

  components: {
    UiList,
    UiListData,
  },

  props: {
    buildingUsers: {
      type: Array,
      required: true
    }
  },

  data() {
    return {

    }
  },

  methods: {

    /****************
     * Delete Section
     ***************/

  },
}
</script>

<style scoped>

</style>
