<template xmlns:ui-card="http://www.w3.org/1999/html">
  <div v-loading="loading">

    <el-tabs type="border-card">
      <!-- Edit user -->
      <el-tab-pane :label="`${$t('actions.edit')} ${$tc('models.user')}`">
        <ui-card
            :heading="`${$t('actions.edit')} ${$tc('models.user')}`"
            class="mb-8"
        >
          <user-form
              v-if="user && user.permissions"
              :user="user"
              @submit="handleSubmit"
              @cancel="handleCancel"
          ></user-form>
        </ui-card>

        <!-- Send reset link to user -->
        <ui-card :heading="$t('auth.reset_password')">
          <ui-link type="danger" @click="handleResetPassword">{{$t('auth.reset_password')}}</ui-link>
        </ui-card>

        <!-- Section for deleting the user -->
        <ui-card :heading="`${$t('actions.delete')} ${$tc('models.user')}`">
          <ui-link type="danger" @click="handleDelete">{{
              `${$t('actions.delete')} ${$tc('models.user')}`
            }}
          </ui-link>
        </ui-card>

        <ui-modal
            :show="showDeleteModal"
            :text="$t('actions.confirm_delete', { model: $tc('models.user') })"
            :confirmText="$t('actions.delete')"
            @confirm="deleteUser"
            @cancel="showDeleteModal = false"
        >
        </ui-modal>
      </el-tab-pane>
      <!-- User courses -->
      <el-tab-pane :label="$tc('models.course', 2)">
        <!-- Card showing user courses -->
        <ui-card
            :heading="$tc('models.course', 2)"
            class="mb-8"
        >
          <user-courses
              :courses="courses"
              class="-mt-4"
              @onDelete="handleUserCourseDelete"
          />
        </ui-card>
      </el-tab-pane>
      <!-- User connections -->
      <el-tab-pane :label="`${$tc('models.buildings')} & ${$tc('models.departments')}`">
        <!-- Card showing buildings and departments a user is part of -->
        <ui-card
            :heading="`${$tc('models.user', 1)}`"
        >
          <department-connections :departments="departments"/>
        </ui-card>

        <!-- Card showing what building users the user is -->
        <ui-card
            :heading="`${$tc('roles.BuildingAdmin')}`"
        >
          <building-connections :building-users="buildingUsers"/>
        </ui-card>

        <!-- Card showing what company users the user is -->
        <ui-card
            :heading="`${$tc('roles.CompanyAdmin')}`"
        >
          <company-connections :company-users="companyUsers"/>
        </ui-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiLink from '@/components/ui/UiLink';
import UiModal from '@/components/ui/UiModal';
import UserForm from '../components/UserForm';
import UserCourses from "../components/UserCourses";
import DepartmentConnections from "../components/DepartmentConnections";
import {
  deleteUser,
  getBuildingUsersByUserId,
  getCompanyUsersByUserId,
  getDepartmentUsersByUser,
  getUser,
  getUserCourses,
  updateUser,
  getUserPermissions,
  putUserPermissions
} from '../api';
import BuildingConnections from "@/app/users/components/BuildingConnections";
import CompanyConnections from "@/app/users/components/CompanyConnections";
import { resetPassword } from '@/app/auth/api';


export default {
  data() {
    return {
      user: null,
      loading: false,
      showDeleteModal: false,
      // Courses
      courses: [],
      // User buildings & departments (connections)
      departments: [],
      buildingUsers: [],
      companyUsers: []
    };
  },

  components: {
    BuildingConnections,
    UiCard,
    UiLink,
    UiModal,
    UserForm,
    UserCourses,
    DepartmentConnections,
    CompanyConnections
  },

  async mounted() {
    let user = await getUser(this.$route.params.id);
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.users", link: "/app/users/"},
      {name: user.email, link: "/app/users/" + user.id + "/edit"}
    ]);
  },

  methods: {
    async getUser() {
      this.loading = true;
      this.user = await getUser(this.$route.params.id);
      this.user.permissions = await getUserPermissions(this.user.id);
      this.loading = false;
    },

    async handleSubmit() {
      this.loading = true;
      var userPermissions = this.user.permissions;
      delete this.user.permissions;
      await updateUser(this.user);
      await putUserPermissions(this.user.id, userPermissions);
      this.$router.back();
      this.loading = false;
    },

    handleDelete() {
      this.showDeleteModal = true;
    },

    async deleteUser() {
      this.loading = true;
      await deleteUser(this.user.id);
      this.showDeleteModal = false;
      this.$router.back();
      this.loading = false;
    },

    handleCancel() {
      this.$router.back();
    },

    async getCourses() {
      this.loading = true;
      const userCourses = await getUserCourses(this.user.id);
      if (userCourses) {
        this.courses = userCourses;
      } else {
        this.courses = [];
      }
      this.loading = false;
    },

    async getDepartments() {
      this.loading = true;
      const departments = await getDepartmentUsersByUser(this.user.id);
      if (departments) {
        this.departments = departments;
      } else {
        this.departments = [];
      }
      this.loading = false;
    },

    async getBuildingUsers() {
      this.loading = true;
      const buildingUsers = await getBuildingUsersByUserId(this.user.id);
      if (buildingUsers) {
        this.buildingUsers = buildingUsers;
      } else {
        this.buildingUsers = [];
      }
      this.loading = false;
    },

    async getCompanyUsers() {
      this.loading = true;
      const companyUsers = await getCompanyUsersByUserId(this.user.id);
      if (companyUsers) {
        this.companyUsers = companyUsers;
      } else {
        this.companyUsers = [];
      }
      this.loading = false;
    },

    handleUserCourseDelete(userCourseId) {
      this.courses = this.courses.filter(x => x.id !== userCourseId);
    },

    async handleResetPassword() {
      this.loading = true;

      var email = this.user.email;

      await resetPassword({email})
        .then(() => {
          this.$message({
            message: `An email has been sent to ${email}`,
            type: 'success'
          });
        })
        .catch(() => {
          this.$message({
            message: 'An error has occured. Try again',
            type: 'error'
          });
        })
        .finally(() => this.loading = false);
    },
  },

  async created() {
    await this.getUser();

    // Getting users courses and setting them
    await this.getCourses();
    // Fetch the departments a user is part off
    await this.getDepartments();
    // Fetch building users by userId
    await this.getBuildingUsers();
    // Fetch company users by userId
    await this.getCompanyUsers();

    this.loading = false;
  },
};
</script>
